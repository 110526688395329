import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-toast-stripe-onboard-success',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
  template: `
    <div class="font-semibold flex justify-center items-center gap-4">
      <img src="assets/img/stripe.jpg" class="h-6" />
      <span i18n>Congratulations, you successfully connected with Stripe!</span>
    </div>
  `,
})
export class ToastStripeOnboardSuccessComponent {}
